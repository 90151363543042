// src/components/ContactSection.js

import React from 'react';
import { motion } from 'framer-motion';
import { MapPin, Mail, Phone } from 'lucide-react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

const ContactSection = () => {
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    // Here you would typically send the form data to your backend
    console.log(values);
    setTimeout(() => {
      alert('Message sent successfully!');
      setSubmitting(false);
      resetForm();
    }, 1000);
  };

  return (
    <section id="contact" className="py-20 bg-gradient-to-b from-white to-ffc0c6/10">
      <div className="container mx-auto px-4">
        <motion.h2 
          className="text-4xl md:text-5xl font-bold text-center mb-16 text-gray-800"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Get in Touch
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h3 className="text-2xl font-semibold mb-6">Send Us a Message</h3>
            <Formik
              initialValues={{ name: '', email: '', subject: '', message: '' }}
              validationSchema={ContactSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form className="space-y-4">
                  <div>
                    <Field name="name" placeholder="Your Name" className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-ffc0c6" />
                    {errors.name && touched.name ? <div className="text-red-500 text-sm mt-1">{errors.name}</div> : null}
                  </div>
                  <div>
                    <Field name="email" type="email" placeholder="Your Email" className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-ffc0c6" />
                    {errors.email && touched.email ? <div className="text-red-500 text-sm mt-1">{errors.email}</div> : null}
                  </div>
                  <div>
                    <Field name="subject" placeholder="Subject" className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-ffc0c6" />
                    {errors.subject && touched.subject ? <div className="text-red-500 text-sm mt-1">{errors.subject}</div> : null}
                  </div>
                  <div>
                    <Field name="message" as="textarea" rows="5" placeholder="Your Message" className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-ffc0c6" />
                    {errors.message && touched.message ? <div className="text-red-500 text-sm mt-1">{errors.message}</div> : null}
                  </div>
                  <motion.button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-ffc0c6 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-opacity-80 transition duration-300"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {isSubmitting ? 'Sending...' : 'Send Message'}
                  </motion.button>
                </Form>
              )}
            </Formik>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <h3 className="text-2xl font-semibold mb-6">Contact Information</h3>
            <div className="space-y-4 mb-8">
              <p className="flex items-center">
                <MapPin className="mr-2 text-ffc0c6" />
                1820 Avenue M, Brooklyn NY, 11230
              </p>
              <p className="flex items-center">
                <Mail className="mr-2 text-ffc0c6" />
                contact@piggybackit.com
              </p>
              <p className="flex items-center">
                <Phone className="mr-2 text-ffc0c6" />
                +1 (929) 299-0816
              </p>
            </div>
            <div className="h-64 rounded-lg overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.9496586601215!2d-74.00601538459426!3d40.71277597933038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a3162e4bdb1%3A0x4a7fa5b4b23c5c2a!2sNew%20York%2C%20NY%2010007%2C%20USA!5e0!3m2!1sen!2s!4v1629484184045!5m2!1sen!2s"
                width="100%"
                height="100%"
                allowFullScreen=""
                loading="lazy"
                title="Google Map showing New York"
                className="border-0"
              ></iframe>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const ContactSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
});

export default ContactSection;
